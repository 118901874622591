"use client"

import { format, endOfDay, endOfToday } from "date-fns"
import { toZonedTime } from "date-fns-tz"
import { TbTruckDelivery } from "react-icons/tb"
import { useTimer } from "react-timer-hook"

const Timer = ({ date }: { date: Date }) => {
  const { seconds, minutes, hours, days } = useTimer({
    expiryTimestamp: date,
    autoStart: true,
  })

  return (
    <div className="grid grid-flow-col gap-5 text-center auto-cols-max text-primary">
      <div className="flex flex-col items-center p-2 bg-neutral rounded-box text-neutral-content">
        <span className="countdown">
          <span
            style={
              {
                "--value": days < 10 ? `0${days}` : days,
              } as React.CSSProperties
            }
          ></span>
        </span>
        dgr
      </div>
      <div className="flex flex-col items-center p-2 bg-neutral rounded-box text-neutral-content">
        <span className="countdown">
          <span
            style={
              {
                "--value": hours < 10 ? `0${hours}` : hours,
              } as React.CSSProperties
            }
          ></span>
        </span>
        tim
      </div>
      <div className="flex flex-col items-center p-2 bg-neutral rounded-box text-neutral-content">
        <span className="countdown">
          <span
            style={
              {
                "--value": minutes < 10 ? `0${minutes}` : minutes,
              } as React.CSSProperties
            }
          ></span>
        </span>
        min
      </div>
      <div className="flex flex-col items-center p-2 bg-neutral rounded-box text-neutral-content">
        <span className="countdown">
          <span
            style={
              {
                "--value": seconds < 10 ? `0${seconds}` : seconds,
              } as React.CSSProperties
            }
          ></span>
        </span>
        sek
      </div>
    </div>
  )
}

const Completed = () => {
  return (
    <div className="bg-grey py-16 md:py-18">
      <div className="content-container flex items-center justify-center flex-col gap-6 text-center text-2xl md:text-4xl">
        <p className="font-heading uppercase font-semibold">
          Tack för denna säsong!
        </p>
        <p className="font-heading uppercase font-semibold">
          Nästa beräknade leverans sker{" "}
          <span className="text-primary">30&nbsp;augusti</span>
        </p>
      </div>
    </div>
  )
}

const CountdownBanner = () => {
  const toDate = toZonedTime(new Date(endOfToday()), "Europe/Stockholm")
  const deliveryDate = toZonedTime(
    new Date("2025-01-29T23:59:59"),
    "Europe/Stockholm"
  )
  const completed =
    toZonedTime(new Date(), "Europe/Stockholm").getTime() > toDate.getTime()

  if (completed) return <Completed />

  return (
    <div className="bg-grey py-8 md:py-18">
      <div className="content-container flex items-center justify-center flex-col gap-6 text-center text-2xl md:text-4xl">
        <div className="bg-neutral rounded-full p-4">
          <TbTruckDelivery size={82} />
        </div>
        <p className="font-heading uppercase font-semibold">Beställ inom</p>
        <Timer date={toDate} />
        <p className="font-heading uppercase font-semibold">
          för beräknad leverans{" "}
          <span className="text-primary">
            {format(deliveryDate, "dd")}&nbsp;{format(deliveryDate, "MMM")}
            &nbsp;
            {new Date().getFullYear() !== deliveryDate.getFullYear() &&
              `${deliveryDate.getFullYear()}`}
          </span>
        </p>
      </div>
    </div>
  )
}

export default CountdownBanner
